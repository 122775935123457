<template>
  <div class="salaire-list">
    <PageHeader
      title="Calculateurs de salaire"
      :has-actions="true"
      :has-back="true"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/calculators/salaire/create')"
      >
        Ajouter
      </md-button>

      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/calculators/salaire/history')"
      >
        Histoire
      </md-button>
    </PageHeader>

    <!-- La liste des calculators -->
    <sortable-list
      link="/admin/calculators/salaire/update/"
      :items="data"
      :parameters="listParameters"
      :hasPagination="true"
    >
    </sortable-list>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      data: [],
      listParameters: [
        {
          name: "SMIC",
          key: "smic",
        },
        {
          name: "Coef 1 à 19",
          key: "coef1a19",
        },
        {
          name: "Coef 20 ou plus",
          key: "coef20plus",
        },
        {
          name: "Taux de charge",
          key: "taux_charge",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/admin/calculators/");
    },
  },
  mounted() {
    calculatorsModel.getAllSalaire().then((data) => {
      this.data = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.impot-revenu-list {
  .debug-log {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>
